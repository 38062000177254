
(function( $ ) {

    $.fn.compactKitGroups = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var groupElements = jqContainer.find('.kit-group');
            
            var makeCotainerOpen = function(container) {
                var groupContainsBlock = container.find('.kit-group-contains').first();
                var groupContainsHolderBlock = container.find('.kit-group-contains .kit-group-contains-holder').first();
                
                container.addClass('open')
                groupContainsBlock.height(groupContainsHolderBlock.innerHeight());
            }

            var mekrContainerClose = function(container) {
                var groupContainsBlock = container.find('.kit-group-contains').first();

                container.removeClass('open')
                groupContainsBlock.height(0);
            }

            var maekKitItemOpen = function(kitContainer, groupContainer) {
                var popupText = kitContainer.find('.info-popup-text').first();
                var popupTextContainer = kitContainer.find('.info-popup-text .text-popup').first();
                
                kitContainer.addClass('open-info');
                popupText.height(popupTextContainer.innerHeight());

                if (groupContainer.hasClass('open')) {
                    setTimeout(() => {
                        makeCotainerOpen(groupContainer); //recalculate height with info now open
                      }, "300");
                }
                
            }

            var mekekitItemClose = function(kitContainer, groupContainer) {
                var popupText = kitContainer.find('.info-popup-text').first();
                
                kitContainer.removeClass('open-info');
                popupText.height(0);

                if (groupContainer.hasClass('open')) {
                    setTimeout(() => {
                        makeCotainerOpen(groupContainer); //recalculate height with info now open
                      }, "300");
                }

            }

            var kitItemInfoPopupSetup = function(container) {
                var kitItems = container.find('.kit-item');
                kitItems.each(function() {
                    var kitItem = $(this);
                    var openLink = kitItem.find('.info-popup>a').first();
                    
                    // set initial state if classes are present
                    if (kitItem.hasClass('open-info')) {
                        maekKitItemOpen(kitItem, container);
                    } else {
                        mekekitItemClose(kitItem, container);
                    }

                    // kit item info open event
                    openLink.click(function(e) {
                        e.preventDefault();
                        if (kitItem.hasClass('open-info')) {
                            mekekitItemClose(kitItem, container);
                        } else {
                            maekKitItemOpen(kitItem, container);
                        }
                        
                    });
                    
                });
            };

            groupElements.each(function() {
                var groupElement = this;
                var jqGroupElement = $(groupElement);
                var groupNameLink = jqGroupElement.find('.kit-group-name .kit-group-name-holder');

                // init kit item info opener
                kitItemInfoPopupSetup(jqGroupElement);

                // set initial state if classes are present
                if (jqGroupElement.hasClass('open')) {
                    makeCotainerOpen(jqGroupElement);
                } else {
                    mekrContainerClose(jqGroupElement);
                }
                
                // main open event
                groupNameLink.click(function(e) {
                    e.preventDefault();
                    if (jqGroupElement.hasClass('open')) {
                        mekrContainerClose(jqGroupElement);
                    } else {
                        makeCotainerOpen(jqGroupElement);
                    }
                    
                });
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.compact-kit-groups-js').compactKitGroups();
});
