
(function( $ ) {

    $.fn.mobileFilter = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var filterElements = jqContainer.find('.filter-element');
            filterElements.each(function() {
                var filterElement = this;
                var jqFilterElement = $(filterElement);
                var filterNameLink = jqFilterElement.find('.filter-name>a');
                var filterContainsBlock = jqFilterElement.find('.filter-contains').first();
                var filterContainsHolderBlock = jqFilterElement.find('.filter-contains .filter-contains-holder').first();
                
                if (jqFilterElement.hasClass('open')) {
                    filterContainsBlock.height(filterContainsHolderBlock.innerHeight());
                } else {
                    filterContainsBlock.height(0);
                }
                
                filterNameLink.click(function(e) {
                    e.preventDefault();
                    // close all filter elements first
                    if (jqFilterElement.hasClass('open')) {
                        jqFilterElement.removeClass('open');
                        filterContainsBlock.height(0);
                    } else {
                        jqFilterElement.addClass('open');
                        filterContainsBlock.height(filterContainsHolderBlock.innerHeight());
                    }
                    
                });
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-filter-js').mobileFilter();
});
