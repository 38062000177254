
(function( $ ) {

    $.fn.catalogFilter = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var filterElements = jqContainer.find('.filter-element');
            var closeAll = function() {
                filterElements.each(function() {
                    var jqFilterElementLocal = $(this);
                    jqFilterElementLocal.removeClass('open');
                });
            }
            filterElements.each(function() {
                var filterElement = this;
                var jqFilterElement = $(filterElement);
                var filterNameLink = jqFilterElement.find('.filter-name>a');
                filterNameLink.click(function(e) {
                    e.preventDefault();
                    // close all filter elements first
                    closeAll();
                    jqFilterElement.addClass('open');
                });
            });
            $('body').click(function(e) {
                var clickedElement = e.target;
                var openedFilterElement = $('.filter-element.open');
                if (openedFilterElement.length <= 0) {
                    
                } else {
                    if (!openedFilterElement[0].contains(clickedElement)) {
                        closeAll();
                    }
                }
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.catalog-filter-js').catalogFilter();
});
