
(function( $ ) {

    $.fn.kitGroups = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var groupElements = jqContainer.find('.kit-group');
            groupElements.each(function() {
                var groupElement = this;
                var jqGroupElement = $(groupElement);
                var groupNameLink = jqGroupElement.find('.kit-group-name .kit-group-name-holder');
                var groupContainsBlock = jqGroupElement.find('.kit-group-contains').first();
                var groupContainsHolderBlock = jqGroupElement.find('.kit-group-contains .kit-group-contains-holder').first();
                
                if (jqGroupElement.hasClass('open')) {
                    groupContainsBlock.height(groupContainsHolderBlock.innerHeight());
                } else {
                    groupContainsBlock.height(0);
                }
                
                groupNameLink.click(function(e) {
                    e.preventDefault();
                    // close all filter elements first
                    if (jqGroupElement.hasClass('open')) {
                        jqGroupElement.removeClass('open');
                        groupContainsBlock.height(0);
                    } else {
                        jqGroupElement.addClass('open');
                        groupContainsBlock.height(groupContainsHolderBlock.innerHeight());
                    }
                    
                });
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.kit-groups-js').kitGroups();
});
